import HttpService from "../client";

const axiosClient = HttpService.getAxiosClient();

const getATProfile = async (urlParams: any) => {
	const response = await axiosClient.get<Blob>(
		`/export/pdf/${urlParams.userId}/${urlParams.language}/${urlParams.variant}`,
		{
			responseType: "blob",
			headers: {
				Accept: "application/pdf",
			},
		},
	);
	return response;
};

const getHtmlProfile = async (urlParams: any) => {
	const response = await axiosClient.get(
		`/export/html/${urlParams.userId}/${urlParams.language}/${urlParams.variant}`,
	);
	return response;
};

const getDBahnProfile = async (urlParams: any) => {
	const response = await axiosClient.get(
		`/export/word/${urlParams.userId}/${urlParams.language}/${urlParams.variant}`,
		{
			responseType: "blob",
			headers: {
				Accept:
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			},
		},
	);
	return response;
};

const GenerateProfileService = {
	getATProfile,
	getHtmlProfile,
	getDBahnProfile,
};

export default GenerateProfileService;
