import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "./ui/tooltip";

const CustomTooltip = (props: {
	content: string;
	children: React.ReactNode;
}) => {
	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>{props.children}</TooltipTrigger>
				<TooltipContent className="w-auto max-w-lg">
					{props.content}
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};

export default CustomTooltip;
