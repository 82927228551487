import CustomNavbar from "../../components/Navbar";

const SkillTree = () => {
	return (
		<>
			<CustomNavbar />
		</>
	);
};

export default SkillTree;
