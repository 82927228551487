import { type IEmployeeProfile } from "../../interfaces/EmployeeProfile";
import HttpService from "../client";
import { getProfileSchema } from "../schemas/ProfileSchema";
import {
	transformProfileBeToFe,
	transformProfileFeToBe,
} from "../transform/transformProfiles";

const axiosClient = HttpService.getAxiosClient();

const getProfile = async (urlParams: any) => {
	const response = await axiosClient.get(
		`/profile/${urlParams.userId}/${urlParams.language}/${urlParams.variant}`,
	);
	const parsedProfile = getProfileSchema.parse(response.data);
	return transformProfileBeToFe(parsedProfile);
};

const postProfileVariant = async (newProfile: IEmployeeProfile) => {
	const transformedProfile = transformProfileFeToBe(newProfile);
	const response = await axiosClient.post(
		`/profile/${newProfile.id}/${newProfile.language}/${newProfile.variantName}`,
		transformedProfile,
	);
	return response;
};

const putProfile = async (urlParams: any, userProfile: IEmployeeProfile) => {
	const transformedProfile = transformProfileFeToBe(userProfile);
	const response = await axiosClient.put(
		`/profile/${urlParams.userId}/${urlParams.language}/${urlParams.variant}`,
		transformedProfile,
	);
	return response;
};

const deleteProfile = async (
	userId: string,
	language: string,
	variant: string,
) => {
	const response = await axiosClient.delete(
		`/profile/${userId}/${language}/${variant}`,
	);
	return response;
};

const ProfileService = {
	getProfile,
	postProfileVariant,
	putProfile,
	deleteProfile,
};

export default ProfileService;
