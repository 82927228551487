import { LogLevel } from "@azure/msal-browser";

import { env } from "../env";

export const msalConfig = {
	auth: {
		clientId: env.REACT_APP_MSAL_CLIENT_ID,
		authority: env.REACT_APP_MSAL_AUTHORITY,
		redirectUri: env.REACT_APP_MSAL_REDIRECT_URI,
		postLogoutRedirectUri: env.REACT_APP_MSAL_POST_LOGOUT_REDIRECT_URI,
		// clientCapabilities: ["CP1"],
		// navigateToLoginRequestUrl: false,
		// knownAuthorities: [],
		// cloudDiscoveryMetadata: "",
		// clientCapabilities: ["CP1"],
	},
	cache: {
		cacheLocation: "sessionStorage",
		storeAuthStateInCookie: false,
		// temporaryCacheLocation: "sessionStorage",
		// secureCookies: false,
		// claimsBasedCachingEnabled: true,
	},
	system: {
		loggerOptions: {
			loggerCallback: (level: any, message: any, containsPii: any) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						// console.info(message);
						return;
					case LogLevel.Verbose:
						// console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
					default:
						return;
				}
			},
		},
	},
};

export const loginRequest = {
	scopes: ["User.Read"],
};
