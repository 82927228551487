export enum ProfileSections {
	INTRO = "intro",
	OVERVIEW = "overview",
	EDUCATION = "education",
	PROJECT_REQUIREMENTS = "projectRequirements",
	JOB_EXPERIENCES = "jobExperiences",
	PROJECT_EXPERIENCES = "projectExperiences",
}

export enum URL_PARAMS {
	USER_ID = "userId",
	LANGUAGE = "language",
	VARIANT = "variant",
}
