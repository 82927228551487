import { z } from "zod";

export const getListProfilesSchema = z.array(
	z.object({
		id: z.string(),
		name: z.string(),
		role: z.string().nullable(),
		profileLanguage: z.array(z.enum(["de", "en"])).optional(),
		// variant: z.array(z.string()),
	}),
);

export const getListProfilesOfUserSchema = z.array(
	/* eslint-disable camelcase */
	z.object({
		user_id: z.string(),
		language: z.enum(["de", "en"]),
		variant_id: z.string(),
		variant_name: z.string().nullable(),
		// name: z.string(),
		// role: z.string(),
	}),
	/* eslint-enable camelcase */
);
