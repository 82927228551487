import localConfig from "./local-config.json";

declare global {
	interface Window {
		env: any;
	}
}

// change with your own variables
type EnvType = {
	REACT_APP_VERSION: string;
	REACT_APP_MSAL_CLIENT_ID: string;
	REACT_APP_MSAL_AUTHORITY: string;
	REACT_APP_MSAL_REDIRECT_URI: string;
	REACT_APP_MSAL_POST_LOGOUT_REDIRECT_URI: string;
	REACT_APP_BACKEND_ADDRESS: string;
};
let env: EnvType;
if (process.env.REACT_APP_DEPLOYMENT === "local") {
	env = { ...localConfig, ...window.env };
} else {
	env = { ...process.env, ...window.env };
}

export { env };
