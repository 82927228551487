import { SaveIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";

import ProfileService from "../../../api/services/ProfileService";
import { EMPTY_EMPLOYEE_PROFILE } from "../../../common/dummyEntries";
import { getParams } from "../../../common/helper";
import { useToast } from "../../../components/ui/use-toast";
import { type IEmployeeProfile } from "../../../interfaces/EmployeeProfile";
import ActionButton from "./ActionButton";
import DeleteProjectModal from "./DeleteProjectModal";
import ExportProfileModal from "./ExportProfileModal";
import NewProjectModal from "./NewProjectModal";

const ProfileActions = (props: { employeeProfile: IEmployeeProfile }) => {
	const { employeeProfile } = props;
	const { t } = useTranslation();

	const [queryParameters] = useSearchParams();
	const { toast } = useToast();

	const putProfileMutation = useMutation({
		mutationFn: () => {
			return ProfileService.putProfile(
				getParams(queryParameters),
				employeeProfile,
			);
		},
		onSuccess: () => {
			toast({
				title: t("fetching.putProfileMutation.onSuccess.title"),
			});
		},
		onError: () => {
			toast({
				title: t("fetching.putProfileMutation.onError.title"),
			});
		},
	});

	const handleSaveProfile = () => {
		if (employeeProfile) {
			putProfileMutation.mutate();
		}
	};

	return (
		<div className="flex flex-row items-start justify-center space-x-4">
			<ActionButton
				// active={true}
				tooltip={t("profileActions.save.tooltip")}
				Icon={SaveIcon}
				onClick={handleSaveProfile}
			/>
			<NewProjectModal
				employeeProfile={employeeProfile || EMPTY_EMPLOYEE_PROFILE}
			/>
			<ExportProfileModal />
			<DeleteProjectModal
				employeeProfile={employeeProfile || EMPTY_EMPLOYEE_PROFILE}
			/>
		</div>
	);
};

export default ProfileActions;
