import { z } from "zod";

const languageSchema = z.object({
	language: z.string(),
	fluency: z.string(),
});

const careerSchema = z.object({
	name: z.string(),
	position: z.string(),
	summary: z.string(),
	startDate: z.string().nullable(),
	endDate: z.string().nullable(),
	industry: z.string().optional(),
});

const projectSchema = z.object({
	goal: z.string(),
	role: z.string(),
	employer: z.string().nullable(),
	startDate: z.string().nullable(),
	endDate: z.string().nullable(),
	industry: z.string(),
	description: z.string(),
	highlights: z.array(z.string()),
	technologies: z.array(z.string()),
});

const requirementsSchema = z.object({
	significance: z.string(),
	requirement: z.string(),
	conformance: z.string(),
});

// const skillsSchema = z.object({
// 	programmingLanguages: z
// 		.object({
// 			name: z.string(),
// 			keywords: z.array(z.string()),
// 		})
// 		.nullable(),
// 	industries: z
// 		.object({
// 			name: z.string(),
// 			keywords: z.array(z.string()),
// 		})
// 		.nullable(),
// 	databases: z
// 		.object({
// 			name: z.string(),
// 			keywords: z.array(z.string()),
// 		})
// 		.nullable(),
// 	frameworks: z
// 		.object({
// 			name: z.string(),
// 			keywords: z.array(z.string()),
// 		})
// 		.nullable(),
// 	tools: z
// 		.object({
// 			name: z.string(),
// 			keywords: z.array(z.string()),
// 		})
// 		.nullable(),
// 	modeling: z
// 		.object({
// 			name: z.string(),
// 			keywords: z.array(z.string()),
// 		})
// 		.nullable(),
// });

const educationSchema = z.object({
	institution: z.string().nullable(),
	discipline: z.string().nullable(),
	qualification: z.string().nullable(),
	startDate: z.string().nullable(),
	endDate: z.string().nullable(),
	// score: z.number().lte(20).gte(0).nullable(),
	score: z.string().nullable(),
	description: z.string().nullable(),
});

export const getProfileSchema = z.object({
	version: z.string(),
	metadata: z.object({
		userId: z.string(),
		language: z.enum(["de", "en"]),
		variantId: z.string(),
		variantName: z.string().nullable(),
		created: z.string(),
		lastEdited: z.string(),
		lastEditor: z.string(),
		// additionalProp1: z.object({}).nullable(),
	}),
	basics: z.object({
		name: z.string(),
		role: z.string().nullable(),
		hashtags: z.array(z.string()),
		professionalFocus: z.array(z.string()),
		industries: z.array(z.string()),
		customEntries: z
			.array(
				z.object({
					header: z.string(),
					content: z.string(),
					additionalProp1: z.object({}).nullable(),
				}),
			)
			.nullable(),
		// additionalProp1: z.object({}).nullable(),
	}),
	education: z.array(educationSchema).nullable(),
	certificates: z.array(z.object({ name: z.string() })).nullable(),
	languages: z.array(languageSchema).nullable(),
	career: z.array(careerSchema).nullable(),
	// skills: skillsSchema,
	projects: z.array(projectSchema).nullable(),
	requirements: z.array(requirementsSchema).nullable(),
	custom: z
		.array(
			z.object({
				title: z.string(),
				rows: z.array(
					z.object({
						header: z.string(),
						content: z.string(),
						additionalProp1: z.object({}),
					}),
				),
				insertAfter: z.string(),
				// additionalProp1: z.object({}),
			}),
		)
		.nullable(),
});
