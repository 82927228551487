import type IProfileList from "../../interfaces/common/IProfileList";
import { type GetListProfilesOfUserSchemaType } from "../schemas/backendTypes";

export const transformProfilesOfUserBeToFe = (
	profiles: GetListProfilesOfUserSchemaType,
): IProfileList[] => {
	return profiles.map((profile) => ({
		id: profile.user_id,
		language: profile.language,
		variantId: profile.variant_id,
		variantName: profile.variant_name || "Hauptprofil",
	}));
};
