import {
	EMPTY_EDUCATION_ENTRY,
	EMPTY_JOB_EXPERIENCE,
	EMPTY_LANGSKILL,
	EMPTY_PROJECT_EXPERIENCE,
	EMPTY_PROJECT_REQUIREMENTS,
} from "../../common/dummyEntries";
import { type IEmployeeProfile } from "../../interfaces/EmployeeProfile/IEmployeeProfile";
import {
	type IEducation,
	type IJobExperience,
	type ILangSkill,
	type Importance,
	type IProjectExperience,
	type IProjectRequirements,
} from "../../interfaces/EmployeeProfile/sections";
import { type GetProfileType } from "../schemas/backendTypes";

export const transformProfileBeToFe = (
	profile: GetProfileType,
): IEmployeeProfile => {
	return {
		id: profile.metadata.userId,
		variantId: profile.metadata.variantId,
		variantName: profile.metadata.variantName || "Hauptprofil",
		language: profile.metadata.language,
		created: profile.metadata.created,
		lastEdited: profile.metadata.lastEdited,
		lastEditor: profile.metadata.lastEditor,
		profile: {
			intro: {
				name: profile.basics.name,
				position: profile.basics.role || "",
				heroSkill: profile.basics.hashtags,
			},
			overview: {
				certifications:
					profile.certificates?.length === 0 || profile.certificates === null
						? []
						: profile.certificates.map((cert) => cert.name),
				industries: profile.basics.industries,
				specialities:
					profile.basics.professionalFocus.length === 0
						? []
						: profile.basics.professionalFocus.map((focus: string) => focus),
				langSkills:
					profile.languages?.length === 0 || profile.languages === null
						? [EMPTY_LANGSKILL]
						: profile.languages.map((lang) => ({
								language: lang.language,
								level: lang.fluency,
							})),
			},
			education:
				profile.education?.length === 0 || profile.education === null
					? [EMPTY_EDUCATION_ENTRY]
					: profile.education.map((edu) => ({
							startDate: edu.startDate ? new Date(edu.startDate) : null,
							endDate: edu.endDate ? new Date(edu.endDate) : null,
							degree: edu.qualification || "",
							institution: edu.institution || "",
							description: edu.description || "",
							untilToday: edu.endDate ? false : true,
						})),
			generalKnowledge: [{ knowledge: "", skills: "" }],
			// softwareEngineering: "TO BE IMPLEMENTED",
			// mlKnowledge: "TO BE IMPLEMENTED",
			// dataPlatform: "TO BE IMPLEMENTED",
			// dataEngineering: "TO BE IMPLEMENTED",
			// dataVisualizaion: "TO BE IMPLEMENTED",
			// devOps: "TO BE IMPLEMENTED",
			// dataMlOps: "TO BE IMPLEMENTED",
			// development: "TO BE IMPLEMENTED",
			// strategy: "TO BE IMPLEMENTED",
			// projectManagement: "TO BE IMPLEMENTED",
			// academy: "TO BE IMPLEMENTED",
			// customKnowledge: [],
			projectRequirements:
				profile.requirements?.length === 0 || profile.requirements === null
					? [EMPTY_PROJECT_REQUIREMENTS]
					: profile.requirements.map((req) => ({
							importance: (req.significance === "optional"
								? "should"
								: "must") as Importance,
							requirement: req.requirement,
							experience: req.conformance,
						})),
			projectExperiences:
				profile.projects?.length === 0 || profile.projects === null
					? [EMPTY_PROJECT_EXPERIENCE]
					: profile.projects.map((project) => ({
							role: project.role || "",
							employer: project.employer || "",
							startDate: project.startDate ? new Date(project.startDate) : null,
							endDate: project.endDate ? new Date(project.endDate) : null,
							businessGoal: project.goal,
							industry: project.industry,
							description: project.description,
							projectTasks:
								project.highlights.length === 0 ? [""] : project.highlights,
							technologies: project.technologies,
							untilToday: project.endDate ? false : true,
						})),
			jobExperiences:
				profile.career?.length === 0 || profile.career === null
					? [EMPTY_JOB_EXPERIENCE]
					: profile.career.map((career) => ({
							jobTitle: career.position,
							role: career.position,
							startDate: career.startDate ? new Date(career.startDate) : null,
							endDate: career.endDate ? new Date(career.endDate) : null,
							companyName: career.name,
							description: career.summary,
							untilToday: career.endDate ? false : true,
							industry: career.industry,
						})),
		},
	};
};

export function transformProfileFeToBe(
	profile: IEmployeeProfile,
): GetProfileType {
	return {
		metadata: {
			userId: profile.id,
			language: profile.language,
			variantId: profile.variantId,
			variantName: profile.variantName,
			created: profile.created,
			lastEdited: profile.lastEdited,
			lastEditor: profile.lastEditor,
			// additionalProp1: {},
		},
		basics: {
			name: profile.profile.intro.name,
			role: profile.profile.intro.position,
			hashtags: profile.profile.intro.heroSkill,
			professionalFocus: profile.profile.overview.specialities,
			industries: profile.profile.overview.industries,
			customEntries: null,
			// additionalProp1: {},
		},
		education: profile.profile.education.map((edu: IEducation) => ({
			institution: edu.institution,
			qualification: edu.degree,
			startDate: edu.startDate?.toISOString().split("T")[0],
			endDate: edu.endDate?.toISOString().split("T")[0],
			description: edu.description,
		})),
		certificates: profile.profile.overview.certifications.map(
			(certification) => {
				return { name: certification };
			},
		),
		languages: profile.profile.overview.langSkills.map(
			(langSkill: ILangSkill) => ({
				language: langSkill.language,
				fluency: langSkill.level,
			}),
		),
		projects: profile.profile.projectExperiences.map(
			(project: IProjectExperience) => ({
				goal: project.businessGoal,
				role: project.role,
				startDate: project.startDate?.toISOString().split("T")[0],
				endDate: project.endDate?.toISOString().split("T")[0],
				industry: project.industry,
				description: project.description,
				highlights:
					project.projectTasks.length === 1 && project.projectTasks[0] === ""
						? []
						: project.projectTasks, // check if projectTasks is empty
				technologies: project.technologies,
				employer: project.employer,
			}),
		),
		requirements: profile.profile.projectRequirements.map(
			(req: IProjectRequirements) => ({
				significance: req.importance,
				requirement: req.requirement,
				conformance: req.experience,
			}),
		),
		career: profile.profile.jobExperiences.map((job: IJobExperience) => ({
			name: job.companyName,
			position: job.jobTitle,
			startDate: job.startDate?.toISOString().split("T")[0],
			endDate: job.endDate?.toISOString().split("T")[0],
			summary: job.description,
			industry: job.industry,
		})),
		custom: null,
	} as GetProfileType;
}
