import type { FC } from "react";
import { useRef } from "react";
import type { Identifier, XYCoord } from "dnd-core";
import { useDrag, useDrop } from "react-dnd";

const ItemTypes = {
	TAGPILL: "tagpill",
};

interface TagPillProps {
	id: any;
	text: string;
	index: number;
	moveTagPill: (dragIndex: number, hoverIndex: number) => void;
	onClick: (indexToRemove: number) => void;
}

interface DragTagPill {
	index: number;
	id: string;
	type: string;
}

export const TagPill: FC<TagPillProps> = ({
	id,
	text,
	index,
	moveTagPill,
	onClick,
}) => {
	const ref = useRef<HTMLDivElement>(null);

	const [{ handlerId }, drop] = useDrop<
		DragTagPill,
		void,
		{ handlerId: Identifier | null }
	>({
		accept: ItemTypes.TAGPILL,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(tagPill: DragTagPill, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = tagPill.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get horizontal middle
			const hoverMiddleX =
				(hoverBoundingRect.right - hoverBoundingRect.left) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();
			// console.log(clientOffset)

			// Get pixels to the left
			const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

			// Only perform the move when the mouse has crossed half of the items width
			// When dragging rightwards, only move when the cursor is after 50%
			// When dragging leftwards, only move when the cursor is before 50%

			// Dragging rightwards
			if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
				return;
			}

			// Dragging leftwards
			if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
				return;
			}

			// Time to actually perform the action
			moveTagPill(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			tagPill.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: ItemTypes.TAGPILL,
		item: () => {
			return { id, index };
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const opacity = isDragging ? "opacity-50" : "opacity-100";
	drag(drop(ref));
	return (
		<div
			ref={ref}
			className={`mb-2 mr-2 inline-flex cursor-move items-center rounded-md bg-orange px-2 py-0.5 text-sm text-white ${opacity}`}
			data-handler-id={handlerId}
		>
			{text}
			<svg
				onClick={() => onClick(index)}
				className="h-4 w-4 cursor-pointer"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M6 18L18 6M6 6l12 12"
				/>
			</svg>
		</div>
	);
};
