import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import commonDE from "./translations/de/common.json";
import commonEN from "./translations/en/common.json";

const resources = {
	en: {
		translation: commonEN,
	},
	de: {
		translation: commonDE,
	},
};

i18next.use(initReactI18next).init({
	resources,
	lng: "de-DE",
	interpolation: {
		escapeValue: false,
	},
});

export default i18next;
