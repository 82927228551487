import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { SKILL_CATALOG_LINK } from "../../../common/constants";
import { Heading } from "../../../components/Typography";

const GeneralKnowledge = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className="mt-5 flex flex-row justify-between">
				<div className="mb-3 ml-3">
					<Heading level={"2"}>{t("generalKnowledge.title")}</Heading>
				</div>
			</div>
			<div className="bg-atMidGrey bg-formTileBackground p-10 sm:rounded-lg">
				<div className="text-text">
					<h3 className="mb-8">
						{t("generalKnowledge.infoText")} (
						<Link
							className="font-bold text-orange underline"
							to={SKILL_CATALOG_LINK}
							target="_blank"
							rel="noopener noreferrer"
						>
							Skill Matrix
						</Link>
						)
					</h3>
					<h3>
						INFO: Skills von Retain werden in zukünftigen Versionen
						implementiert
					</h3>
				</div>
			</div>
		</>
	);
};

export default GeneralKnowledge;
