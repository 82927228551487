import { type IEmployeeProfile } from "../interfaces/EmployeeProfile";
import {
	Importance,
	type IEducation,
	type IGeneralKnowledge,
	type IIntroduction,
	type IJobExperience,
	type ILangSkill,
	type IOverview,
	type IProjectExperience,
	type IProjectRequirements,
} from "../interfaces/EmployeeProfile/sections";

const EMPTY_INTRODUCTION: IIntroduction = {
	position: "",
	name: "",
	heroSkill: [],
};

export const EMPTY_PROJECT_REQUIREMENTS: IProjectRequirements = {
	importance: Importance.MUST,
	requirement: "",
	experience: "",
};

const EMPTY_GENERAL_KNOWLEDGE: IGeneralKnowledge = {
	knowledge: "",
	skills: "",
	// softwareEngineering: "",
	// mlKnowledge: "",
	// dataPlatform: "",
	// dataEngineering: "",
	// dataVisualizaion: "",
	// devOps: "",
	// dataMlOps: "",
	// development: "",
	// strategy: "",
	// projectManagement: "",
	// academy: "",
	// customKnowledge: [],
};

export const EMPTY_JOB_EXPERIENCE: IJobExperience = {
	startDate: null,
	endDate: null,
	untilToday: false,
	companyName: "",
	jobTitle: "",
	description: "",
	industry: "",
};

export const EMPTY_PROJECT_EXPERIENCE: IProjectExperience = {
	role: "",
	employer: "",
	startDate: null,
	endDate: null,
	untilToday: false,
	businessGoal: "",
	industry: "",
	description: "",
	projectTasks: [""],
	technologies: [],
};

export const EMPTY_EDUCATION_ENTRY: IEducation = {
	startDate: null,
	endDate: null,
	untilToday: false,
	degree: "",
	institution: "",
	description: "",
};

export const EMPTY_LANGSKILL: ILangSkill = {
	language: "",
	level: "",
};

const EMPTY_OVERVIEW: IOverview = {
	certifications: [],
	industries: [],
	specialities: [],
	langSkills: [{ ...EMPTY_LANGSKILL }],
};

export const EMPTY_EMPLOYEE_PROFILE: IEmployeeProfile = {
	id: "",
	variantId: "",
	variantName: "",
	language: "",
	created: "",
	lastEdited: "",
	lastEditor: "",
	profile: {
		intro: EMPTY_INTRODUCTION,
		overview: EMPTY_OVERVIEW,
		education: [{ ...EMPTY_EDUCATION_ENTRY }],
		generalKnowledge: [{ ...EMPTY_GENERAL_KNOWLEDGE }],
		projectRequirements: [{ ...EMPTY_PROJECT_REQUIREMENTS }],
		jobExperiences: [{ ...EMPTY_JOB_EXPERIENCE }],
		projectExperiences: [{ ...EMPTY_PROJECT_EXPERIENCE }],
	},
};
