import type ISelectOption from "../interfaces/common/ISelectOption";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "./ui/select";

const CustomSelect = (props: {
	onChange: any;
	value: string;
	placeholder: string;
	options: ISelectOption[];
	className?: string;
	disabled?: boolean;
}) => {
	const {
		onChange,
		value,
		placeholder,
		options,
		className,
		disabled = false,
	} = props;

	return (
		<Select onValueChange={onChange} value={value} disabled={disabled}>
			<SelectTrigger
				className={className ? className : "w-[180px] border text-text"}
			>
				<SelectValue placeholder={placeholder} />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectLabel className="text-text">{placeholder}</SelectLabel>
					{options.map((language: ISelectOption) => (
						<SelectItem
							key={language.id}
							value={language.id}
							className="text-text hover:bg-navbar focus:text-text active:text-text"
						>
							{language.name}
						</SelectItem>
					))}
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};

// const compareSelect = (
// 	list1: ISelectOption[],
// 	list2: ISelectOption[],
// ): boolean => {
// 	const hasSameObjects = list1.every((obj: ISelectOption, index: number) => {
// 		const obj2 = list2[index];
// 		const newComp = obj?.id === obj2?.id && obj?.name === obj2?.name;
// 		return newComp;
// 	});
// 	const hasSameLength = list1.length === list2.length;
// 	return hasSameObjects && hasSameLength;
// };

// export default memo(CustomSelect, (prevProps, nextProps) => {
// 	return (
// 		compareSelect(prevoptions, nextoptions) &&
// 		prevvalue === nextvalue &&
// 		prevplaceholder === nextProps.placeholder
// 	);
// });

export default CustomSelect;
