import { useState } from "react";
import { TrashIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";

import ProfileService from "../../../api/services/ProfileService";
import { REACT_QUERY_KEYS } from "../../../common/constants";
import { URL_PARAMS } from "../../../common/enums";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../../../components/ui/dialog";
import { toast } from "../../../components/ui/use-toast";
import { type IEmployeeProfile } from "../../../interfaces/EmployeeProfile";
import ActionButton from "./ActionButton";

type DeleteProjectModalProps = {
	employeeProfile: IEmployeeProfile;
};
const DeleteProjectModal = (props: DeleteProjectModalProps) => {
	const { employeeProfile } = props;

	const [open, setOpen] = useState(false);

	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const [queryParameters, setSearchParams] = useSearchParams();
	const paramUserId = queryParameters.get(URL_PARAMS.USER_ID);
	const paramLanguage = queryParameters.get(URL_PARAMS.LANGUAGE);
	const paramVariant = queryParameters.get(URL_PARAMS.VARIANT);

	const deleteProfileMutation = useMutation({
		mutationFn: () => {
			return ProfileService.deleteProfile(
				employeeProfile.id,
				employeeProfile.language,
				employeeProfile.variantId,
			);
		},
		onSuccess: () => {
			setOpen(false);
			toast({
				title: t("fetching.deleteProfileMutation.onSuccess.title"),
			});
			queryClient.invalidateQueries(REACT_QUERY_KEYS.GET_PROFILES_OF_USER);
			setSearchParams({
				userId: employeeProfile.id,
				language: employeeProfile.language,
				variant: "main",
			});
		},
		onError: () => {
			toast({
				title: t("fetching.deleteProfileMutation.onError.title"),
				description: t("fetching.deleteProfileMutation.onError.description"),
			});
		},
	});

	const deleteProfile = () => {
		const response = deleteProfileMutation.mutate();
		if (response !== undefined) {
			setOpen(false);
		}
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger>
				<ActionButton
					tooltip={t("profileActions.delete.tooltip")}
					Icon={TrashIcon}
					disabled={paramVariant === "main" ? true : false}
				/>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{t("misc.deleteProfileModal.title")}</DialogTitle>
					<DialogDescription></DialogDescription>
				</DialogHeader>
				<div className="absolute right-10 top-3">
					{deleteProfileMutation.isLoading && <LoadingSpinner />}
				</div>
				<ul className="mb-10 mt-10">
					<li>
						<span className="font-bold">
							{t("misc.deleteProfileModal.name")}:{" "}
						</span>
						{paramUserId}
					</li>
					<li>
						<span className="font-bold">
							{t("misc.deleteProfileModal.language")}:{" "}
						</span>
						{paramLanguage}
					</li>
					<li>
						<span className="font-bold">
							{t("misc.deleteProfileModal.variant")}:{" "}
						</span>
						{paramVariant}
					</li>
				</ul>
				<DialogFooter>
					<button
						className="h-10 w-32 rounded bg-orange py-2 text-white"
						type="submit"
						onClick={() => deleteProfile()}
					>
						Löschen
					</button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default DeleteProjectModal;
