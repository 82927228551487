import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { useCallback, useMemo, useState, type ChangeEvent } from "react";
import {
	DndContext,
	DragOverlay,
	MouseSensor,
	useSensor,
	useSensors,
	type DragEndEvent,
	type DragStartEvent,
} from "@dnd-kit/core";
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { GripVerticalIcon, Trash2Icon } from "lucide-react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "~/components/ui/accordion";
import { Button } from "~/components/ui/button";

import {
	INDUSTRY_OPTIONS_ENGLISH,
	INDUSTRY_OPTIONS_GERMAN,
} from "../../../common/constants";
import { EMPTY_PROJECT_EXPERIENCE } from "../../../common/dummyEntries";
import { getLastDayOfMonth } from "../../../common/helper";
import AddButton from "../../../components/AddButton";
import Autocomplete from "../../../components/Autocomplete";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomInput from "../../../components/CustomInput";
import CustomLabel from "../../../components/CustomLabel";
import { CustomTagPillInput } from "../../../components/CustomTagPillInput";
import CustomTextarea from "../../../components/CustomTextarea";
import DeleteButton from "../../../components/DeleteButton";
import { Heading } from "../../../components/Typography";
import CalendarIcon from "../../../icons/calendarIcon.svg";
import { type IProjectExperience } from "../../../interfaces/EmployeeProfile/sections";

interface ProjectExperienceProps {
	projectExperiences: IProjectExperience[];
	setProjectExperiences: (projectExperiences: IProjectExperience[]) => void;
}

const ProjectExperience = ({
	projectExperiences,
	setProjectExperiences,
}: ProjectExperienceProps) => {
	const { t, i18n } = useTranslation();

	// Memoize sensors to avoid unnecessary re-initializations
	const sensors = useSensors(useSensor(MouseSensor));

	const [overlayPE, setOverlayPE] = useState<IProjectExperience | null>(null);
	const [accordionOpen, setAccordionOpen] = useState<string | undefined>(
		undefined,
	);

	// Memoize industry options based on language
	const activeIndustryOptions = useMemo(() => {
		return i18n.language === "de-DE"
			? INDUSTRY_OPTIONS_GERMAN
			: INDUSTRY_OPTIONS_ENGLISH;
	}, [i18n.language]);

	// Utility function to update state immutably
	const updateProjectExperience = useCallback(
		(index: number, updatedFields: Partial<IProjectExperience>) => {
			const newState = [...projectExperiences];
			newState[index] = { ...newState[index], ...updatedFields };
			setProjectExperiences(newState);
		},
		[projectExperiences, setProjectExperiences],
	);

	const setTags = useCallback(
		(field: keyof IProjectExperience, index: number, tags: string[]) => {
			updateProjectExperience(index, { [field]: tags });
		},
		[updateProjectExperience],
	);

	const handleDateChange = useCallback(
		(date: Date | null, key: "endDate" | "startDate", index: number) => {
			if (!date) return;

			const newDate = new Date(
				date.getTime() - date.getTimezoneOffset() * 60000,
			);
			const updatedDate =
				key === "endDate" ? getLastDayOfMonth(newDate) : newDate;
			updateProjectExperience(index, { [key]: updatedDate });
		},
		[updateProjectExperience],
	);

	const handleInputChange = useCallback(
		(
			event: ChangeEvent<HTMLInputElement>,
			key: keyof IProjectExperience,
			index: number,
		) => {
			updateProjectExperience(index, { [key]: event.target.value });
		},
		[updateProjectExperience],
	);

	const handleCheckbox = useCallback(
		(checked: boolean, index: number) => {
			updateProjectExperience(index, { untilToday: checked, endDate: null });
		},
		[updateProjectExperience],
	);

	const handleSelectIndustry = useCallback(
		(value: string, index: number) => {
			const selectedIndustry =
				activeIndustryOptions.find(
					(option) => option.name.toLowerCase() === value.toLowerCase(),
				)?.name || "";
			updateProjectExperience(index, { industry: selectedIndustry });
		},
		[activeIndustryOptions, updateProjectExperience],
	);

	const handleProjectTasksChange = useCallback(
		(
			e: ChangeEvent<HTMLInputElement>,
			projectIndex: number,
			taskIndex: number,
		) => {
			const updatedTasks = [...projectExperiences[projectIndex].projectTasks];
			updatedTasks[taskIndex] = e.target.value;
			updateProjectExperience(projectIndex, { projectTasks: updatedTasks });
		},
		[projectExperiences, updateProjectExperience],
	);

	const handleAddTask = useCallback(
		(projectIndex: number, taskIndex: number) => {
			const updatedTasks = [...projectExperiences[projectIndex].projectTasks];
			updatedTasks.splice(taskIndex + 1, 0, "");
			updateProjectExperience(projectIndex, { projectTasks: updatedTasks });
		},
		[projectExperiences, updateProjectExperience],
	);

	const handleDeleteTask = useCallback(
		(projectIndex: number, taskIndex: number) => {
			const updatedTasks =
				projectExperiences[projectIndex].projectTasks.length === 1
					? [""]
					: projectExperiences[projectIndex].projectTasks.filter(
							(_, j) => j !== taskIndex,
						);
			updateProjectExperience(projectIndex, { projectTasks: updatedTasks });
		},
		[projectExperiences, updateProjectExperience],
	);

	const handleAddProject = useCallback(() => {
		setProjectExperiences([
			...projectExperiences,
			{
				...EMPTY_PROJECT_EXPERIENCE,
				startDate: null,
				endDate: null,
				id: uuidv4(),
			},
		]);
	}, [projectExperiences, setProjectExperiences]);

	const handleDeleteProject = useCallback(
		(index: number) => {
			if (projectExperiences.length === 1) {
				setProjectExperiences([{ ...EMPTY_PROJECT_EXPERIENCE }]);
			} else {
				setProjectExperiences(projectExperiences.filter((_, i) => i !== index));
			}
		},
		[projectExperiences, setProjectExperiences],
	);

	const handleDragStart = useCallback(
		(event: DragStartEvent) => {
			const activePE = projectExperiences.find(
				(project) => project.id === event.active.id,
			);
			setOverlayPE(activePE ?? null);
		},
		[projectExperiences],
	);

	const handleDragEnd = useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event;
			if (over && active.id !== over.id) {
				const activeIndex = projectExperiences.findIndex(
					(project) => project.id === active.id,
				);
				const overIndex = projectExperiences.findIndex(
					(project) => project.id === over.id,
				);
				setProjectExperiences(
					arrayMove(projectExperiences, activeIndex, overIndex),
				);
			}
			setOverlayPE(null);
		},
		[projectExperiences, setProjectExperiences],
	);

	return (
		<>
			<div className="my-3 ml-3">
				<Heading level="2">{t("projectExperience.title")}</Heading>
			</div>
			<div className="bg-atMidGrey flex flex-col bg-formTileBackground p-10 sm:rounded-lg">
				<Accordion
					type="single"
					value={accordionOpen}
					onValueChange={setAccordionOpen}
					collapsible
				>
					<DndContext
						onDragStart={handleDragStart}
						onDragEnd={handleDragEnd}
						sensors={sensors}
					>
						<SortableContext
							items={projectExperiences.map((project) => project.id)}
							strategy={verticalListSortingStrategy}
						>
							{projectExperiences.map((project, projectIndex) => (
								<PEAccordionItem
									key={project.id}
									project={project}
									projectIndex={projectIndex}
									handleDateChange={handleDateChange}
									handleInputChange={handleInputChange}
									handleCheckbox={handleCheckbox}
									handleSelectIndustry={handleSelectIndustry}
									handleProjectTasksChange={handleProjectTasksChange}
									handleAddTask={handleAddTask}
									handleDeleteTask={handleDeleteTask}
									setTags={setTags}
									handleDeleteProject={handleDeleteProject}
								/>
							))}
						</SortableContext>

						{typeof document !== "undefined" &&
							createPortal(
								<DragOverlay>
									{overlayPE && (
										<PEAccordionItem
											project={overlayPE}
											projectIndex={-1}
											isOverlay
										/>
									)}
								</DragOverlay>,
								document.body,
							)}
					</DndContext>
				</Accordion>
				<Button className="mt-4" onClick={handleAddProject}>
					{t("projectExperience.addButton.label")}
				</Button>
			</div>
		</>
	);
};

const PEAccordionItem = ({
	project,
	projectIndex,
	isOpen = false,
	handleDateChange = () => {},
	handleInputChange = () => {},
	handleCheckbox = () => {},
	handleSelectIndustry = () => {},
	handleProjectTasksChange = () => {},
	handleAddTask = () => {},
	handleDeleteTask = () => {},
	setTags = () => {},
	handleDeleteProject = () => {},
	isOverlay = false, // Default to false
}: {
	project: IProjectExperience;
	projectIndex: number;
	isOpen?: boolean;
	handleDateChange?: (
		date: Date | null,
		key: "endDate" | "startDate",
		index: number,
	) => void;
	handleInputChange?: (
		event: ChangeEvent<HTMLInputElement>,
		key: "role" | "industry" | "businessGoal" | "description" | "employer",
		index: number,
	) => void;
	handleCheckbox?: (checked: boolean, index: number) => void;
	handleSelectIndustry?: (value: string, index: number) => void;
	handleProjectTasksChange?: (
		e: ChangeEvent<HTMLInputElement>,
		projectIndex: number,
		taskIndex: number,
	) => void;
	handleAddTask?: (projectIndex: number, taskIndex: number) => void;
	handleDeleteTask?: (projectIndex: number, taskIndex: number) => void;
	setTags?: (
		field: keyof IProjectExperience,
		index: number,
		tags: string[],
	) => void;
	handleDeleteProject?: (index: number) => void;
	isOverlay?: boolean;
}) => {
	const { t } = useTranslation();

	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({
			id: project.id,
			disabled: isOpen,
		});
	const style = {
		transition,
		transform: CSS.Transform.toString(transform),
	};

	const calculateAccordionItemTitle = useCallback(
		(project: IProjectExperience) => {
			const startDate = project.startDate?.getFullYear()
				? project.startDate?.getFullYear()
				: "-";
			const projectDegree = project.businessGoal ? project.businessGoal : "-";
			if (startDate === "-" && projectDegree === "-") {
				return t("misc.accordion.noTitle");
			}
			return `${startDate} ${projectDegree}`;
		},
		[t],
	);

	return (
		<div className="flex w-full flex-row gap-3" ref={setNodeRef} style={style}>
			<Button
				variant="ghost"
				className="mt-2 h-auto cursor-grab p-1"
				{...attributes}
				{...listeners}
				disabled={isOpen}
			>
				<span className="sr-only">Projekterfahrung bewegen</span>
				<GripVerticalIcon className="h-6 text-secondary-foreground/50" />
			</Button>
			{isOverlay ? (
				<div className="border bg-formTileBackground p-3 text-left text-base text-white">
					{calculateAccordionItemTitle(project)}
				</div>
			) : (
				<>
					<AccordionItem value={projectIndex.toString()} className="w-full">
						<AccordionTrigger className="w-full text-left">
							{calculateAccordionItemTitle(project)}
						</AccordionTrigger>
						<PEAccordionContent
							project={project}
							projectIndex={projectIndex}
							handleDateChange={handleDateChange}
							handleInputChange={handleInputChange}
							handleCheckbox={handleCheckbox}
							handleSelectIndustry={handleSelectIndustry}
							handleProjectTasksChange={handleProjectTasksChange}
							handleAddTask={handleAddTask}
							handleDeleteTask={handleDeleteTask}
							setTags={setTags}
						/>
					</AccordionItem>
					<Button
						size="icon"
						variant="destructive"
						className="mt-3 flex h-8 w-8 items-center justify-around text-white"
						onClick={() => handleDeleteProject(projectIndex)}
					>
						<Trash2Icon className="h-5 w-5" />
					</Button>
				</>
			)}
		</div>
	);
};

const PEAccordionContent = ({
	project,
	projectIndex,
	handleDateChange,
	handleInputChange,
	handleCheckbox,
	handleSelectIndustry,
	handleProjectTasksChange,
	handleAddTask,
	handleDeleteTask,
	setTags,
}: {
	project: IProjectExperience;
	projectIndex: number;
	handleDateChange: (
		date: Date | null,
		key: "endDate" | "startDate",
		index: number,
	) => void;
	handleInputChange: (
		event: ChangeEvent<HTMLInputElement>,
		key: "role" | "industry" | "businessGoal" | "description" | "employer",
		index: number,
	) => void;
	handleCheckbox: (checked: boolean, index: number) => void;
	handleSelectIndustry: (value: string, index: number) => void;
	handleProjectTasksChange: (
		e: ChangeEvent<HTMLInputElement>,
		projectIndex: number,
		taskIndex: number,
	) => void;
	handleAddTask: (projectIndex: number, taskIndex: number) => void;
	handleDeleteTask: (projectIndex: number, taskIndex: number) => void;
	setTags: (
		field: keyof IProjectExperience,
		index: number,
		tags: string[],
	) => void;
}) => {
	const { t, i18n } = useTranslation();

	return (
		<AccordionContent>
			<div className="grid grid-cols-6 space-y-2">
				<div className="col-span-6">
					<CustomLabel
						label={t("projectExperience.date.label")}
						tooltip={t("projectExperience.date.tooltip")}
					/>
					<div className="items-center space-y-2 sm:flex sm:flex-row sm:space-x-2 sm:space-y-0">
						<div className="relative">
							<DatePicker
								showIcon
								icon={<img src={CalendarIcon} alt="Calendaricon" />}
								className="rounded border-border bg-inputBackground leading-none text-text focus:border-orange focus:outline-none focus:ring-0"
								selected={project.startDate}
								onChange={(date: Date | null) =>
									handleDateChange(date, "startDate", projectIndex)
								}
								dateFormat="MMMM yyyy"
								showMonthYearPicker
								selectsStart
								startDate={project.startDate ?? new Date()}
								endDate={project.endDate ?? new Date()}
								placeholderText={t(
									"projectExperience.date.startDatePlaceholder",
								)}
							/>
						</div>
						<span className="text-text sm:mx-4">-</span>
						<div className="relative">
							<DatePicker
								disabled={project.untilToday}
								showIcon
								icon={<img src={CalendarIcon} alt="Calendaricon" />}
								className="rounded border-border bg-inputBackground leading-none text-text focus:border-orange focus:outline-none focus:ring-0 disabled:opacity-40"
								selected={project.endDate}
								onChange={(date: Date | null) =>
									handleDateChange(date, "endDate", projectIndex)
								}
								dateFormat="MMMM yyyy"
								showMonthYearPicker
								showFullMonthYearPicker
								selectsEnd
								startDate={project.startDate ?? new Date()}
								endDate={project.endDate ?? new Date()}
								minDate={project.startDate ?? new Date()}
								placeholderText={t("projectExperience.date.endDatePlaceholder")}
							/>
						</div>
						<CustomCheckbox
							label="Bis Heute"
							checked={project.untilToday}
							setChecked={(checked: boolean) =>
								handleCheckbox(checked, projectIndex)
							}
						/>
					</div>
				</div>
				<div className="col-span-6">
					<CustomLabel
						label={t("projectExperience.employer.label")}
						tooltip={t("projectExperience.employer.tooltip")}
					/>
					<CustomInput
						type="text"
						value={project.employer}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleInputChange(e, "employer", projectIndex)
						}
						placeholder={t("projectExperience.employer.placeholder")}
					/>
				</div>
				<div className="col-span-6">
					<CustomLabel
						label={t("projectExperience.projectTitle.label")}
						tooltip={t("projectExperience.projectTitle.tooltip")}
					/>
					<CustomInput
						value={project.role}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleInputChange(e, "role", projectIndex)
						}
						type="text"
						placeholder={t("projectExperience.projectTitle.placeholder")}
					/>
				</div>
				<div className="col-span-6">
					<CustomLabel
						label={t("projectExperience.industry.label")}
						tooltip={t("projectExperience.industry.tooltip")}
					/>
					<Autocomplete
						placeholderClosed={t("overview.industries.label")}
						placeholderOpened={t("overview.industries.label")}
						placeholderNotFound={t("overview.industries.label")}
						options={
							i18n.language === "de-DE"
								? INDUSTRY_OPTIONS_GERMAN
								: INDUSTRY_OPTIONS_ENGLISH
						}
						value={project.industry}
						onChange={(value: string) =>
							handleSelectIndustry(value, projectIndex)
						}
					/>
				</div>
				<div className="col-span-6">
					<CustomLabel
						label={t("projectExperience.goal.label")}
						tooltip={t("projectExperience.goal.tooltip")}
					/>
					<div className="flex flex-col">
						<CustomInput
							width="w-auto"
							value={project.businessGoal}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								handleInputChange(e, "businessGoal", projectIndex)
							}
							type="text"
							placeholder={t("projectExperience.goal.placeholder")}
						/>
					</div>
				</div>
				<div className="col-span-6">
					<CustomLabel
						label={t("projectExperience.description.label")}
						tooltip={t("projectExperience.description.tooltip")}
					/>
					<div className="flex flex-col">
						<CustomTextarea
							value={project.description}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								handleInputChange(e, "description", projectIndex)
							}
							rows={7}
							placeholder={t("projectExperience.description.placeholder")}
						/>
					</div>
				</div>
				<div className="col-span-6">
					<CustomLabel
						label={t("projectExperience.tasks.label")}
						tooltip={t("projectExperience.tasks.tooltip")}
					/>
					{project.projectTasks.map((task: string, taskIndex: number) => {
						return (
							<div
								className="mb-2 flex flex-row items-center space-x-3"
								key={taskIndex}
							>
								<CustomTextarea
									value={task}
									onChange={(e: ChangeEvent<HTMLInputElement>) =>
										handleProjectTasksChange(e, projectIndex, taskIndex)
									}
									width={"w-full"}
									placeholder={t("projectExperience.tasks.placeholder")}
								/>

								<AddButton
									className={"pr-2"}
									onClick={() => handleAddTask(projectIndex, taskIndex)}
								/>
								<DeleteButton
									onClick={() => handleDeleteTask(projectIndex, taskIndex)}
								/>
							</div>
						);
					})}
				</div>
				<div className="col-span-6">
					<CustomLabel
						label={t("projectExperience.technologies.label")}
						tooltip={t("projectExperience.technologies.tooltip")}
					/>
					<div className="flex flex-col">
						<CustomTagPillInput
							placeholder={t("projectExperience.technologies.placeholder")}
							setTags={(technologies: string[]) =>
								setTags("technologies", projectIndex, technologies)
							}
							tags={project.technologies}
						/>
					</div>
				</div>
			</div>
		</AccordionContent>
	);
};

export default ProjectExperience;
