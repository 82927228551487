import axios from "axios";

import { env } from "../env";

const _axios = axios.create({ baseURL: env.REACT_APP_BACKEND_ADDRESS });

const HttpMethods = {
	GET: "GET",
	POST: "POST",
	DELETE: "DELETE",
	PUT: "PUT",
};

const getIdTokenSecret = () => {
	const tokenId = `msal.token.keys.${env.REACT_APP_MSAL_CLIENT_ID}`;
	const tokens = sessionStorage.getItem(tokenId);
	if (tokens) {
		const parsedTokens = JSON.parse(tokens);
		const idTokenName = parsedTokens["idToken"][0];
		const idTokenItem = sessionStorage.getItem(idTokenName);
		if (idTokenItem) {
			const parsedIdToken = JSON.parse(idTokenItem);
			return parsedIdToken["secret"];
		}
	}
};

const configure = () => {
	_axios.interceptors.request.use(
		(requestConfig: any) => {
			const idTokenSecret = getIdTokenSecret();
			if (requestConfig.headers !== undefined && idTokenSecret !== null) {
				requestConfig.headers.Authorization = `Bearer ${idTokenSecret}`;
			}
			return Promise.resolve(requestConfig);
		},
		(error) => {
			return Promise.reject(error);
		},
	);
};

const getAxiosClient = () => _axios;

const client = {
	configure,
	getAxiosClient,
	HttpMethods,
};

export default client;
