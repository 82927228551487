import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@radix-ui/react-popover";
import { Globe } from "lucide-react";
import { useTranslation } from "react-i18next";

import { useRefreshTokens } from "../hooks/useBackendTokenCheckExpirationTime";
import MaxWidthWrapper from "./MaxWidthWrapper";
import { Button } from "./ui/button";

const CustomNavbar = () => {
	const { i18n } = useTranslation();

	const { instance } = useMsal();
	const activeAccount = instance.getAllAccounts()[0];
	useRefreshTokens();

	const handleLogout = () => {
		instance.logout();
	};

	const activeLanguage = i18n.language === "de-DE" ? "de" : "en";
	const changeLanguage = (newLanguage: string) => {
		i18n.changeLanguage(newLanguage);
	};

	return (
		<nav className="sticky top-0 z-50 flex h-12 w-full bg-navbar py-2">
			<MaxWidthWrapper className="flex items-center justify-between">
				<div className="flex flex-row justify-between">
					<a href="/" className="hidden sm:flex sm:items-center">
						<span className="self-center whitespace-nowrap text-2xl font-semibold text-white">
							[at] Profile Hub
						</span>
					</a>
				</div>
				<div className="flex items-center gap-5">
					<Popover>
						<PopoverTrigger asChild>
							<Button
								variant="ghost"
								size="icon"
								className="rounded-full text-white"
							>
								<Globe />
							</Button>
						</PopoverTrigger>
						<PopoverContent align="end">
							<div className="z-50 my-4 list-none divide-y divide-gray-100 rounded-lg bg-inputBackground shadow">
								<ul className="py-2" aria-labelledby="user-menu-button">
									<Button
										variant={activeLanguage !== "de" ? "ghost" : "secondary"}
										onClick={() => changeLanguage("de-DE")}
										className="w-full"
									>
										Deutsch
									</Button>
								</ul>
								<ul className="py-2" aria-labelledby="user-menu-button">
									<Button
										variant={activeLanguage !== "en" ? "ghost" : "secondary"}
										onClick={() => changeLanguage("en-US")}
										className="w-full"
									>
										English
									</Button>
								</ul>
							</div>
						</PopoverContent>
					</Popover>
					<Popover>
						<PopoverTrigger asChild>
							<Button
								size="icon"
								type="button"
								className="flex h-10 w-10 items-center justify-center rounded-full bg-base text-lg text-text"
							>
								{activeAccount ? activeAccount.name?.split(" ")[0][0] : "?"}
							</Button>
						</PopoverTrigger>
						<PopoverContent align="end">
							<div className="text-inputText absolute right-0 z-50 my-4 mr-4 list-none divide-y divide-gray-100 rounded-lg bg-inputBackground shadow">
								<div className="px-4 py-3">
									<span className="block text-sm font-bold">
										{activeAccount.name}
									</span>
									<span className="block truncate text-sm">
										{activeAccount.username}
									</span>
								</div>
								<ul className="py-2" aria-labelledby="user-menu-button">
									<AuthenticatedTemplate>
										<button
											onClick={handleLogout}
											className="flex w-full items-start px-4 py-2 text-sm hover:text-text"
										>
											Logout
										</button>
									</AuthenticatedTemplate>
								</ul>
							</div>
						</PopoverContent>
					</Popover>
				</div>
			</MaxWidthWrapper>
		</nav>
	);
};

export default CustomNavbar;
