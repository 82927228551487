import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";

import { type Tag } from "../common/customTypes";
import { orderTags } from "../common/helper";
import CustomInput from "./CustomInput";
import { TagPill } from "./CustomTagPill";

type CustomTagPillInputProps = FC<{
	tags: string[];
	setTags: any;
	placeholder: string;
	classname?: string;
	sideButton?: any;
}>;

export const CustomTagPillInput: CustomTagPillInputProps = ({
	tags,
	setTags,
	placeholder,
	sideButton,
}) => {
	const [tagsOrdered, setTagsOrdered] = useState<Tag[]>(orderTags(tags));
	const [tagInput, setTagInput] = useState<string>("");

	useEffect(() => {
		setTagsOrdered(orderTags(tags));
	}, [tags]);

	const moveTagPill = useCallback((dragIndex: number, hoverIndex: number) => {
		setTagsOrdered((prevTagPill: Tag[]) =>
			update(prevTagPill, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevTagPill[dragIndex] as Tag],
				],
			}),
		);
	}, []);

	const removeTag = (indexToRemove: number) => {
		const filteredTags = tagsOrdered.filter((tag: Tag) => {
			const bo = tag.id !== indexToRemove;
			return bo;
		});
		const orderedTags = filteredTags.map((tag: Tag, index: number) => {
			return { id: index, text: tag.text };
		});
		setTagsOrdered(orderedTags);
		setTags(orderedTags.map((tag) => tag.text));
	};

	const handleTagInput = (e: any) => {
		if (e.key === "Enter") {
			const newTagInput = tagInput.trim();
			const newTags = [
				...tagsOrdered,
				{ id: tagsOrdered.length, text: newTagInput },
			];
			setTagsOrdered(newTags);
			setTags(newTags.map((tag) => tag.text));
			setTagInput("");
		}
	};

	return (
		<div className="flex flex-col">
			<div className="mb-2 flex flex-row flex-wrap rounded border border-dashed px-2 pt-2">
				{tagsOrdered.length > 0 ? (
					tagsOrdered.map((tag, i) => (
						<TagPill
							key={tag.id}
							index={i}
							id={tag.id}
							text={tag.text}
							moveTagPill={moveTagPill}
							onClick={removeTag}
						/>
					))
				) : (
					<div className="h-8" />
				)}
			</div>
			{sideButton ? (
				<div className="flex flex-row">
					<CustomInput
						value={tagInput}
						onChange={(e: any) => setTagInput(e.target.value)}
						onKeyDown={handleTagInput}
						type="text"
						placeholder={placeholder}
					/>
					{sideButton}
				</div>
			) : (
				<CustomInput
					value={tagInput}
					onChange={(e: any) => setTagInput(e.target.value)}
					onKeyDown={handleTagInput}
					type="text"
					placeholder={placeholder}
				/>
			)}
		</div>
	);
};
