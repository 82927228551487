import { cn } from "../lib/utils";
import AddButton from "./AddButton";
import DeleteButton from "./DeleteButton";

type AddDeleteGroupProps = {
	handleAdd: (index: number) => void;
	handleDelete: (index: number) => void;
	classname?: string;
};

const AddDeleteGroup = (props: AddDeleteGroupProps) => {
	return (
		<div
			className={cn(
				"flex flex-row items-center justify-center",
				props.classname,
			)}
		>
			<AddButton className={"mr-2"} onClick={props.handleAdd} />
			<DeleteButton onClick={props.handleDelete} />
		</div>
	);
};

export default AddDeleteGroup;
