import type ISelectOption from "../interfaces/common/ISelectOption";

export enum REACT_QUERY_KEYS {
	GET_PROFILE = "getProfile",
	GET_PROFILES_OF_USER = "getListProfilesOfUser",
	GET_PROFILES = "getListProfiles",
	GET_IMAGE = "getImage",
}

export const SKILL_CATALOG_LINK =
	"https://athamm.sharepoint.com/:x:/r/sites/DataFactoryPractice/_layouts/15/Doc.aspx?sourcedoc=%7B9A8270FB-C21D-41C1-9B63-CD9BE5D07557%7D&file=Skill-Catalogue.xlsx&wdLOR=cA78B81A7-0D53-A845-A4FC-3F5991F23C04&action=default&mobileredirect=true";

export const LANGUAGE_OPTION_GERMAN = { id: "de", name: "Deutsch" };
export const LANGUAGE_OPTION_ENGLISH = { id: "en", name: "Englisch" };

export const LANGUAGES_ENGLISH: ISelectOption[] = [
	{ id: "af", name: "Afrikaans" },
	{ id: "sq", name: "Albanian" },
	{ id: "am", name: "Amharic" },
	{ id: "ar", name: "Arabic" },
	{ id: "hy", name: "Armenian" },
	{ id: "az", name: "Azerbaijani" },
	{ id: "eu", name: "Basque" },
	{ id: "be", name: "Belarusian" },
	{ id: "bn", name: "Bengali" },
	{ id: "bs", name: "Bosnian" },
	{ id: "bg", name: "Bulgarian" },
	{ id: "ca", name: "Catalan" },
	{ id: "ceb", name: "Cebuano" },
	{ id: "ny", name: "Chichewa" },
	{ id: "zh", name: "Chinese (Simplified)" },
	{ id: "zh-TW", name: "Chinese (Traditional)" },
	{ id: "co", name: "Corsican" },
	{ id: "hr", name: "Croatian" },
	{ id: "cs", name: "Czech" },
	{ id: "da", name: "Danish" },
	{ id: "nl", name: "Dutch" },
	{ id: "en", name: "English" },
	{ id: "eo", name: "Esperanto" },
	{ id: "et", name: "Estonian" },
	{ id: "tl", name: "Filipino" },
	{ id: "fi", name: "Finnish" },
	{ id: "fr", name: "French" },
	{ id: "fy", name: "Frisian" },
	{ id: "gl", name: "Galician" },
	{ id: "ka", name: "Georgian" },
	{ id: "de", name: "German" },
	{ id: "el", name: "Greek" },
	{ id: "gu", name: "Gujarati" },
	{ id: "ht", name: "Haitian Creole" },
	{ id: "ha", name: "Hausa" },
	{ id: "haw", name: "Hawaiian" },
	{ id: "iw", name: "Hebrew" },
	{ id: "hi", name: "Hindi" },
	{ id: "hmn", name: "Hmong" },
	{ id: "hu", name: "Hungarian" },
	{ id: "is", name: "Icelandic" },
	{ id: "ig", name: "Igbo" },
	{ id: "id", name: "Indonesian" },
	{ id: "ga", name: "Irish" },
	{ id: "it", name: "Italian" },
	{ id: "ja", name: "Japanese" },
	{ id: "jw", name: "Javanese" },
	{ id: "kn", name: "Kannada" },
	{ id: "kk", name: "Kazakh" },
	{ id: "km", name: "Khmer" },
	{ id: "ko", name: "Korean" },
	{ id: "ku", name: "Kurdish" },
	{ id: "ky", name: "Kyrgyz" },
	{ id: "lo", name: "Lao" },
	{ id: "la", name: "Latin" },
	{ id: "lv", name: "Latvian" },
	{ id: "lt", name: "Lithuanian" },
	{ id: "lb", name: "Luxembourgish" },
	{ id: "mk", name: "Macedonian" },
	{ id: "mg", name: "Malagasy" },
	{ id: "ms", name: "Malay" },
	{ id: "ml", name: "Malayalam" },
	{ id: "mt", name: "Maltese" },
	{ id: "mi", name: "Maori" },
	{ id: "mr", name: "Marathi" },
	{ id: "mn", name: "Mongolian" },
	{ id: "my", name: "Myanmar (Burmese)" },
	{ id: "ne", name: "Nepali" },
	{ id: "no", name: "Norwegian" },
	{ id: "or", name: "Odia (Oriya)" },
	{ id: "ps", name: "Pashto" },
	{ id: "fa", name: "Persian" },
	{ id: "pl", name: "Polish" },
	{ id: "pt", name: "Portuguese" },
	{ id: "pa", name: "Punjabi" },
	{ id: "ro", name: "Romanian" },
	{ id: "ru", name: "Russian" },
	{ id: "sm", name: "Samoan" },
	{ id: "gd", name: "Scots Gaelic" },
	{ id: "sr", name: "Serbian" },
	{ id: "st", name: "Sesotho" },
	{ id: "sn", name: "Shona" },
	{ id: "sd", name: "Sindhi" },
	{ id: "si", name: "Sinhala" },
	{ id: "sk", name: "Slovak" },
	{ id: "sl", name: "Slovenian" },
	{ id: "so", name: "Somali" },
	{ id: "es", name: "Spanish" },
	{ id: "su", name: "Sundanese" },
	{ id: "sw", name: "Swahili" },
	{ id: "sv", name: "Swedish" },
	{ id: "tg", name: "Tajik" },
	{ id: "ta", name: "Tamil" },
	{ id: "tt", name: "Tatar" },
	{ id: "te", name: "Telugu" },
	{ id: "th", name: "Thai" },
	{ id: "tr", name: "Turkish" },
	{ id: "tk", name: "Turkmen" },
	{ id: "uk", name: "Ukrainian" },
	{ id: "ur", name: "Urdu" },
	{ id: "ug", name: "Uyghur" },
	{ id: "uz", name: "Uzbek" },
	{ id: "vi", name: "Vietnamese" },
	{ id: "cy", name: "Welsh" },
	{ id: "xh", name: "Xhosa" },
	{ id: "yi", name: "Yiddish" },
	{ id: "yo", name: "Yoruba" },
	{ id: "zu", name: "Zulu" },
];

export const LANGUAGES_GERMAN: { id: string; name: string }[] = [
	{ id: "af", name: "Afrikaans" },
	{ id: "sq", name: "Albanisch" },
	{ id: "am", name: "Amharisch" },
	{ id: "ar", name: "Arabisch" },
	{ id: "hy", name: "Armenisch" },
	{ id: "az", name: "Aserbaidschanisch" },
	{ id: "eu", name: "Baskisch" },
	{ id: "be", name: "Weißrussisch" },
	{ id: "bn", name: "Bengalisch" },
	{ id: "bs", name: "Bosnisch" },
	{ id: "bg", name: "Bulgarisch" },
	{ id: "ca", name: "Katalanisch" },
	{ id: "ceb", name: "Cebuano" },
	{ id: "ny", name: "Chichewa" },
	{ id: "zh", name: "Chinesisch (Vereinfacht)" },
	{ id: "zh-TW", name: "Chinesisch (Traditionell)" },
	{ id: "co", name: "Korsisch" },
	{ id: "hr", name: "Kroatisch" },
	{ id: "cs", name: "Tschechisch" },
	{ id: "da", name: "Dänisch" },
	{ id: "nl", name: "Niederländisch" },
	{ id: "en", name: "Englisch" },
	{ id: "eo", name: "Esperanto" },
	{ id: "et", name: "Estnisch" },
	{ id: "tl", name: "Filipino" },
	{ id: "fi", name: "Finnisch" },
	{ id: "fr", name: "Französisch" },
	{ id: "fy", name: "Friesisch" },
	{ id: "gl", name: "Galizisch" },
	{ id: "ka", name: "Georgisch" },
	{ id: "de", name: "Deutsch" },
	{ id: "el", name: "Griechisch" },
	{ id: "gu", name: "Gujarati" },
	{ id: "ht", name: "Haitianisch" },
	{ id: "ha", name: "Haussa" },
	{ id: "haw", name: "Hawaiisch" },
	{ id: "iw", name: "Hebräisch" },
	{ id: "hi", name: "Hindi" },
	{ id: "hmn", name: "Hmong" },
	{ id: "hu", name: "Ungarisch" },
	{ id: "is", name: "Isländisch" },
	{ id: "ig", name: "Igbo" },
	{ id: "id", name: "Indonesisch" },
	{ id: "ga", name: "Irisch" },
	{ id: "it", name: "Italienisch" },
	{ id: "ja", name: "Japanisch" },
	{ id: "jw", name: "Javanisch" },
	{ id: "kn", name: "Kannada" },
	{ id: "kk", name: "Kasachisch" },
	{ id: "km", name: "Khmer" },
	{ id: "ko", name: "Koreanisch" },
	{ id: "ku", name: "Kurdisch" },
	{ id: "ky", name: "Kirgisisch" },
	{ id: "lo", name: "Laotisch" },
	{ id: "la", name: "Lateinisch" },
	{ id: "lv", name: "Lettisch" },
	{ id: "lt", name: "Litauisch" },
	{ id: "lb", name: "Luxemburgisch" },
	{ id: "mk", name: "Mazedonisch" },
	{ id: "mg", name: "Madagassisch" },
	{ id: "ms", name: "Malaiisch" },
	{ id: "ml", name: "Malayalam" },
	{ id: "mt", name: "Maltesisch" },
	{ id: "mi", name: "Maori" },
	{ id: "mr", name: "Marathi" },
	{ id: "mn", name: "Mongolisch" },
	{ id: "my", name: "Myanmarisch (Birmanisch)" },
	{ id: "ne", name: "Nepali" },
	{ id: "no", name: "Norwegisch" },
	{ id: "or", name: "Odia (Oriya)" },
	{ id: "ps", name: "Paschtu" },
	{ id: "fa", name: "Persisch" },
	{ id: "pl", name: "Polnisch" },
	{ id: "pt", name: "Portugiesisch" },
	{ id: "pa", name: "Punjabi" },
	{ id: "ro", name: "Rumänisch" },
	{ id: "ru", name: "Russisch" },
	{ id: "sm", name: "Samoanisch" },
	{ id: "gd", name: "Schottisch-Gälisch" },
	{ id: "sr", name: "Serbisch" },
	{ id: "st", name: "Sesotho" },
	{ id: "sn", name: "Shona" },
	{ id: "sd", name: "Sindhi" },
	{ id: "si", name: "Singhalesisch" },
	{ id: "sk", name: "Slowakisch" },
	{ id: "sl", name: "Slowenisch" },
	{ id: "so", name: "Somali" },
	{ id: "es", name: "Spanisch" },
	{ id: "su", name: "Sundanesisch" },
	{ id: "sw", name: "Suaheli" },
	{ id: "sv", name: "Schwedisch" },
	{ id: "tg", name: "Tadschikisch" },
	{ id: "ta", name: "Tamilisch" },
	{ id: "tt", name: "Tatarisch" },
	{ id: "te", name: "Telugu" },
	{ id: "th", name: "Thailändisch" },
	{ id: "tr", name: "Türkisch" },
	{ id: "tk", name: "Turkmenisch" },
	{ id: "uk", name: "Ukrainisch" },
	{ id: "ur", name: "Urdu" },
	{ id: "ug", name: "Uigurisch" },
	{ id: "uz", name: "Usbekisch" },
	{ id: "vi", name: "Vietnamesisch" },
	{ id: "cy", name: "Walisisch" },
	{ id: "xh", name: "Xhosa" },
	{ id: "yi", name: "Jiddisch" },
	{ id: "yo", name: "Yoruba" },
	{ id: "zu", name: "Zulu" },
];

export const LANGUAGE_LEVELS_GERMAN = [
	{ id: "C2", name: "Muttersprache" },
	{ id: "C1", name: "Fließend" },
	{ id: "B2", name: "Verhandlungssicher" },
	{ id: "B1", name: "Fortgeschritten" },
	{ id: "A2", name: "Grundkenntnisse" },
	// { id: "A1", name: "NEW IMPL" },
];

export const LANGUAGE_LEVELS_ENGLISH = [
	{ id: "C2", name: "Native Speaker" },
	{ id: "C1", name: "Fluent" },
	{ id: "B2", name: "Proficient" },
	{ id: "B1", name: "Advanced" },
	{ id: "A2", name: "Basic" },
	// { id: "A1", name: "NEW IMPL" },
];

export const INDUSTRY_OPTIONS_ENGLISH: ISelectOption[] = [
	{ id: "automotive", name: "Automotive" },
	{ id: "manufacturing", name: "Manufacturing" },
	{ id: "transport", name: "Transport" },
	{ id: "logistics", name: "Logistics" },
	{ id: "banking", name: "Banking / Finance" },
	{ id: "insurance", name: "Insurance" },
	{ id: "energy", name: "Energy" },
	{ id: "utilities", name: "Utilities" },
	{ id: "retail", name: "Retail / Consumer Goods" },
	{ id: "chemical", name: "Chemical" },
	{ id: "pharma", name: "Pharmaceutical / Healthcare" },
	{ id: "public", name: "Public " },
	{ id: "media", name: "Media / Entertainment" },
	{ id: "tech", name: "Technology" },
	{ id: "communication", name: "Communication" },
	{ id: "academics", name: "Academics" },
	{ id: "construction", name: "Construction" },
];

export const INDUSTRY_OPTIONS_GERMAN: ISelectOption[] = [
	{ id: "automotive", name: "Automobilindustrie" },
	{ id: "manufacturing", name: "Produktion" },
	{ id: "transport", name: "Transportwesen" },
	{ id: "logistics", name: "Logistik" },
	{ id: "banking", name: "Bankwesen / Finanzen" },
	{ id: "insurance", name: "Versicherung" },
	{ id: "energy", name: "Energie" },
	{ id: "utilities", name: "Versorgungsunternehmen" },
	{ id: "retail", name: "Einzelhandel / Konsumgüter" },
	{ id: "chemical", name: "Chemie" },
	{ id: "pharma", name: "Pharmazeutik / Gesundheitswesen" },
	{ id: "public", name: "Öffentlicher Sektor" },
	{ id: "media", name: "Medien / Unterhaltung" },
	{ id: "tech", name: "Technologie" },
	{ id: "communication", name: "Kommunikation" },
	{ id: "academics", name: "Bildung & Forschung" },
	{ id: "construction", name: "Bauwesen" },
];
