import { useState } from "react";
import { DownloadIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";

import GenerateProfileService from "../../../api/services/GenerateProfileService";
import { URL_PARAMS } from "../../../common/enums";
import { getParams } from "../../../common/helper";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../../../components/ui/dialog";
import AtIcon from "../../../icons/AT_Logo_Homepage.svg";
import DBahnIcon from "../../../icons/db_logo.png";
import ActionButton from "./ActionButton";

/**
 * Download data as file.
 *
 * @param data
 * @param fileName
 * @param fileType
 */
const downloadData = (data: any, fileName: string, fileType: string) => {
	const fileURL = window.URL.createObjectURL(
		new Blob([data], {
			type: fileType,
		}),
	);
	const link = document.createElement("a");
	link.href = fileURL;
	link.setAttribute("download", fileName);
	link.style.display = "none";
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	window.URL.revokeObjectURL(fileURL);
};

/**
 * Opens the file in a new tab and lets you download it directly.
 * @param data
 * @param fileName
 * @param fileType
 */
const openFileInNewTab = (data: any, fileName: string, fileType: string) => {
	// Create a blob from the data
	const blob = new Blob([data], { type: fileType });
	const fileURL = window.URL.createObjectURL(blob);

	// Open the file in a new tab
	const newTab = window.open();
	if (newTab) {
		// Write HTML content to the new tab
		newTab.document.write(`
            <html>
            <body>
                <embed src="${fileURL}" width="100%" height="100%" />
                <br/>
                <a href="${fileURL}" download="${fileName}" style="position: absolute; top: 10px; right: 10px; padding: 10px; background-color: #4CAF50; color: white; text-decoration: none; border-radius: 5px;">Download ${fileName}</a>
            </body>
            </html>
        `);
		newTab.document.close(); // Close document to render content
	}

	setTimeout(() => {
		window.URL.revokeObjectURL(fileURL);
	}, 10000); // Adjust timeout as needed
};

const ExportProfileModal = () => {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const [queryParameters] = useSearchParams();
	const paramUserId = queryParameters.get(URL_PARAMS.USER_ID);
	const paramLanguage = queryParameters.get(URL_PARAMS.LANGUAGE);
	const paramVariant = queryParameters.get(URL_PARAMS.VARIANT);
	const [open, setOpen] = useState(false);

	const getATProfileQuery = useMutation({
		mutationFn: () => {
			setLoading(true);
			return GenerateProfileService.getATProfile(getParams(queryParameters));
		},
		onSuccess: (response) => {
			setOpen(false);
			const filename =
				response.headers["content-disposition"].split("filename=")[1];
			openFileInNewTab(response.data, filename, "application/pdf");
			setLoading(false);
		},
		onError: () => {
			setLoading(false);
		},
	});

	const getDBahnProfileQuery = useMutation({
		mutationFn: () => {
			setLoading(true);
			return GenerateProfileService.getDBahnProfile(getParams(queryParameters));
		},
		onSuccess: (response) => {
			setOpen(false);
			const filename =
				response.headers["content-disposition"].split("filename=")[1];
			downloadData(
				response.data,
				filename,
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			);
			setLoading(false);
		},
		onError: () => {
			setLoading(false);
		},
	});

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger>
				<ActionButton
					tooltip={t("profileActions.export.tooltip")}
					Icon={DownloadIcon}
				/>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{t("misc.exportProfileModal.title")}</DialogTitle>
					<DialogDescription></DialogDescription>
				</DialogHeader>
				<div className="absolute right-10 top-3">
					{loading && <LoadingSpinner />}
				</div>
				<ul className="ml-10">
					<li>
						<span className="font-bold">
							{t("misc.exportProfileModal.name")}:{" "}
						</span>
						{paramUserId}
					</li>
					<li>
						<span className="font-bold">
							{t("misc.exportProfileModal.language")}:{" "}
						</span>
						{paramLanguage}
					</li>
					<li>
						<span className="font-bold">
							{t("misc.exportProfileModal.variant")}:{" "}
						</span>
						{paramVariant}
					</li>
				</ul>
				<div className="flex flex-row items-center justify-center space-x-10">
					<button disabled={loading} onClick={() => getATProfileQuery.mutate()}>
						<img
							src={AtIcon}
							alt="at logo"
							className={`h-24 w-24 ${loading && "opacity-30"}`}
						/>
					</button>
					<button
						disabled={loading}
						onClick={() => getDBahnProfileQuery.mutate()}
					>
						<img
							src={DBahnIcon}
							alt="at logo"
							className={`mb-3 h-16 w-24 ${loading && "opacity-30"}`}
						/>
					</button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ExportProfileModal;
