import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

const TOKEN_CHECK_INTERVAL = 1000 * 60 * 5; // 5 minutes in milliseconds

/**
 * This hook refreshes the token every 5 minutes.
 * @returns
 */
export const useRefreshTokens = () => {
	const { instance, accounts } = useMsal();

	useEffect(() => {
		const acquireTokenWithRefreshToken = async () => {
			try {
				if (accounts.length && instance) {
					// const response = await instance.acquireTokenSilent({
					// 	account: accounts[0],
					// 	scopes: loginRequest.scopes,
					// });
				}
			} catch (error) {
				console.error("Error refreshing token", error);
			}
		};

		const interval = setInterval(() => {
			acquireTokenWithRefreshToken(); // Call async function without returning a Promise
		}, TOKEN_CHECK_INTERVAL);

		return () => clearInterval(interval);
	}, [accounts, instance]);
};
