import { Checkbox } from "./ui/checkbox";

const CustomCheckbox = (props: {
	label: string;
	checked: boolean;
	setChecked: any;
}) => {
	return (
		<div className="flex items-center space-x-2 pl-6">
			<Checkbox
				className="border-border bg-input"
				checked={props.checked}
				onCheckedChange={(checked) => props.setChecked(checked)}
			/>
			<label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
				{props.label}
			</label>
		</div>
	);
};

export default CustomCheckbox;
