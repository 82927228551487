import { InteractionStatus } from "@azure/msal-browser";
import {
	AuthenticatedTemplate,
	useIsAuthenticated,
	useMsal,
} from "@azure/msal-react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { loginRequest } from "./auth/auth-config";
import { Toaster } from "./components/ui/toaster";
import EmployeeProfile from "./pages/employeeProfile/EmployeeProfile";
import SkillTree from "./pages/skillTree/SkillTree";

const App = () => {
	document.documentElement.classList.add("dark");

	const isAuthenticated = useIsAuthenticated();
	const { instance, inProgress } = useMsal();
	if (inProgress === InteractionStatus.None && !isAuthenticated) {
		instance.loginRedirect(loginRequest);
	}

	return (
		<AuthenticatedTemplate>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Navigate to="/profile" />} />
					<Route path="/profile" element={<EmployeeProfile />} />
					<Route path="/skilltree" element={<SkillTree />} />
				</Routes>
				<Toaster />
			</BrowserRouter>
		</AuthenticatedTemplate>
	);
};

export default App;
