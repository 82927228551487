import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import {
	INDUSTRY_OPTIONS_ENGLISH,
	INDUSTRY_OPTIONS_GERMAN,
	LANGUAGE_LEVELS_ENGLISH,
	LANGUAGE_LEVELS_GERMAN,
	LANGUAGES_ENGLISH,
	LANGUAGES_GERMAN,
} from "../../../common/constants";
import { EMPTY_LANGSKILL } from "../../../common/dummyEntries";
import { URL_PARAMS } from "../../../common/enums";
import AddDeleteGroup from "../../../components/AddDeleteGroup";
import Autocomplete from "../../../components/Autocomplete";
import CustomLabel from "../../../components/CustomLabel";
import CustomSelect from "../../../components/CustomSelect";
import { CustomTagPillInput } from "../../../components/CustomTagPillInput";
import TagPillAutocomplete from "../../../components/TagPillAutocomplete";
import { Heading } from "../../../components/Typography";
import {
	type ILangSkill,
	type IOverview,
} from "../../../interfaces/EmployeeProfile/sections";

interface OverviewProps {
	overview: IOverview;
	setOverview: (overview: IOverview) => void;
}
const Overview = (props: OverviewProps) => {
	const { overview, setOverview } = props;
	const { t, i18n } = useTranslation();

	const [queryParameters] = useSearchParams();
	const paramLanguage = queryParameters.get(URL_PARAMS.LANGUAGE);

	const activeLanguageOptions = paramLanguage?.includes("en")
		? LANGUAGES_ENGLISH
		: LANGUAGES_GERMAN;
	const activeLanguageLevels = paramLanguage?.includes("en")
		? LANGUAGE_LEVELS_ENGLISH
		: LANGUAGE_LEVELS_GERMAN;

	const setTags = (field: keyof IOverview, specialities: string[]) => {
		const newState: IOverview = { ...overview };
		newState[field] = specialities;
		setOverview(newState);
	};

	const handleSelectChange = (
		value: string,
		childKey: "language" | "level",
		index: number,
	) => {
		const newState = { ...overview };
		if (childKey === "language") {
			const newLanguage = activeLanguageOptions.find(
				(language) => language.name.toLowerCase() === value.toLowerCase(),
			)?.id;
			newState.langSkills[index][childKey] = newLanguage as string;
		} else {
			newState.langSkills[index][childKey] = value;
		}
		setOverview(newState);
	};

	const addOptionFormHandler = (index: number) => {
		const emptyLangskill = { ...EMPTY_LANGSKILL };
		const newState = [...overview["langSkills"]];
		newState.splice(index + 1, 0, emptyLangskill);
		setOverview({ ...overview, langSkills: newState });
	};

	const deleteOptionFormHandler = (index: number) => {
		if (overview.langSkills.length === 1) {
			setOverview({
				...overview,
				langSkills: [{ ...EMPTY_LANGSKILL }],
			});
		} else {
			const newState = overview["langSkills"]?.filter(
				(_: any, i: number) => i !== index,
			);
			setOverview({
				...overview,
				langSkills: newState || [],
			});
		}
	};

	const industryOptions =
		i18n.language === "de-DE"
			? INDUSTRY_OPTIONS_GERMAN
			: INDUSTRY_OPTIONS_ENGLISH;

	return (
		<>
			<div className="mb-3 ml-3 mt-5">
				<Heading level={"2"}>{t("overview.title")}</Heading>
			</div>
			<div className="bg-atMidGrey bg-formTileBackground p-10 sm:rounded-lg">
				<div className="grid grid-cols-6 gap-2">
					<div className={"col-span-6 sm:col-span-2"}>
						<CustomLabel
							label={t("overview.skills.label")}
							tooltip={t("overview.skills.tooltip")}
						/>
					</div>
					<div className={"col-span-6 sm:col-span-4"}>
						<CustomTagPillInput
							placeholder={t("overview.skills.placeholder")}
							setTags={(specialities: string[]) =>
								setTags("specialities", specialities)
							}
							tags={overview.specialities}
						/>
					</div>

					<div className={"col-span-6 mt-5 sm:col-span-2"}>
						<CustomLabel
							label={t("overview.certifications.label")}
							tooltip={t("overview.certifications.tooltip")}
						/>
					</div>
					<div className={"col-span-6 mt-5 sm:col-span-4"}>
						<CustomTagPillInput
							placeholder={t("overview.certifications.placeholder")}
							setTags={(certifications: string[]) =>
								setTags("certifications", certifications)
							}
							tags={overview.certifications}
						/>
					</div>

					<div className={"col-span-6 mt-5 sm:col-span-2"}>
						<CustomLabel
							label={t("overview.industries.label")}
							tooltip={t("overview.industries.tooltip")}
						/>
					</div>
					<div className={"col-span-6 mt-5 sm:col-span-4"}>
						<TagPillAutocomplete
							placeholderClosed={t("overview.industries.placeholderClosed")}
							placeholderOpened={t("overview.industries.placeholderOpened")}
							placeholderNotFound={t("overview.industries.placeholderNotFound")}
							options={industryOptions}
							tags={overview.industries}
							setTags={(industries: string[]) =>
								setTags("industries", industries)
							}
						/>
					</div>
					<div className={"col-span-6 mt-5 sm:col-span-2"}>
						<CustomLabel
							label={t("overview.languageSkills.label")}
							tooltip={t("overview.languageSkills.tooltip")}
						/>
					</div>
					<div className={"col-span-6 mt-5 sm:col-span-4"}>
						{overview.langSkills.map((langSkill: ILangSkill, i) => {
							return (
								<div
									className="mb-2 flex flex-col space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0"
									key={i}
								>
									<Autocomplete
										useId={true}
										placeholderOpened={t(
											"overview.languageSkills.languageAutocomplete.placeholderOpened",
										)}
										placeholderClosed={t(
											"overview.languageSkills.languageAutocomplete.placeholderClosed",
										)}
										placeholderNotFound={t(
											"overview.languageSkills.languageAutocomplete.placeholderNotFound",
										)}
										options={activeLanguageOptions}
										value={langSkill.language}
										onChange={(e: string) =>
											handleSelectChange(e, "language", i)
										}
									/>
									<CustomSelect
										onChange={(e: string) => handleSelectChange(e, "level", i)}
										value={langSkill.level}
										placeholder={t("overview.languageSkills.levelPlaceholder")}
										options={activeLanguageLevels}
									/>
									<AddDeleteGroup
										handleAdd={() => addOptionFormHandler(i)}
										handleDelete={() => deleteOptionFormHandler(i)}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default Overview;
