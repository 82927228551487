import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import App from "./App";
import { msalConfig } from "./auth/auth-config";

import "./i18next";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { QueryClient, QueryClientProvider } from "react-query";

import client from "./api/client";

// if (msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
//     msalInstance.setActiveAccount(msalInstance.getActiveAccount());
// }
// msalInstance.addEventCallback((event: any) => {
//     if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
//         const account = event.payload.account;
//         msalInstance.setActiveAccount(null);
//     }
// });

const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);
root.render(
	<React.StrictMode>
		<MsalProvider instance={msalInstance}>
			<QueryClientProvider client={queryClient}>
				<DndProvider backend={HTML5Backend}>
					<App />
				</DndProvider>
			</QueryClientProvider>
		</MsalProvider>
	</React.StrictMode>,
);
client.configure();
