import type ISelectOption from "../interfaces/common/ISelectOption";
import { type Tag } from "./customTypes";
import { URL_PARAMS } from "./enums";

/**
 * This function sorts the objectList by the name attribute.
 * @param objectList
 * @returns
 */
export const sortObjectList = (
	objectList: ISelectOption[],
): ISelectOption[] => {
	return objectList.sort((a: ISelectOption, b: ISelectOption) => {
		if (a.name < b.name) return -1;
		if (a.name > b.name) return 1;
		return 0;
	});
};

export const orderTags = (tags: string[]): Tag[] => {
	return tags.map((tag, index) => {
		return { id: index, text: tag };
	});
};

export const getLastDayOfMonth = (date: Date): Date => {
	const year = date.getFullYear();
	const month = date.getMonth();
	const lastDay = new Date(year, month + 1, 0);
	return lastDay;
};

export const getParams = (queryParameters: URLSearchParams) => {
	return {
		[URL_PARAMS.USER_ID]: queryParameters.get(URL_PARAMS.USER_ID),
		[URL_PARAMS.LANGUAGE]: queryParameters.get(URL_PARAMS.LANGUAGE),
		[URL_PARAMS.VARIANT]: queryParameters.get(URL_PARAMS.VARIANT),
	};
};
