import { t } from "i18next";

import { type IEmployeeProfile } from "../interfaces/EmployeeProfile";
import EmployeeSelect from "../pages/employeeProfile/components/EmployeeSelect";
import ProfileActions from "../pages/employeeProfile/components/ProfileActions";
import { Heading } from "./Typography";

type SidebarProps = {
	employeeProfile: IEmployeeProfile;
};
const Sidebar = (props: SidebarProps) => {
	const { employeeProfile } = props;

	return (
		<div className="fixed rounded-md bg-formTileBackground py-8 pl-8 pr-14">
			<div className="mb-5">
				<Heading level={"1"}>{t("profileFilter.title")}</Heading>
			</div>
			<div className="mb-8">
				<EmployeeSelect employeeProfile={props.employeeProfile} />
			</div>
			<ProfileActions employeeProfile={employeeProfile as IEmployeeProfile} />
		</div>
	);
};

export default Sidebar;
