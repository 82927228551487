import React, { type ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { EMPTY_PROJECT_REQUIREMENTS } from "../../../common/dummyEntries";
import AddButton from "../../../components/AddButton";
import CustomSelect from "../../../components/CustomSelect";
import CustomTextarea from "../../../components/CustomTextarea";
import DeleteButton from "../../../components/DeleteButton";
import { Heading } from "../../../components/Typography";
import type ISelectOption from "../../../interfaces/common/ISelectOption";
import {
	Importance,
	type IProjectRequirements,
} from "../../../interfaces/EmployeeProfile/sections";

interface ProjectRequirementsProps {
	projectRequirements: IProjectRequirements[];
	setProjectRequirements: (projectRequirements: IProjectRequirements[]) => void;
}
const ProjectRequirements = (props: ProjectRequirementsProps) => {
	const { projectRequirements, setProjectRequirements } = props;
	const { t } = useTranslation();

	const ImportanceOptions: ISelectOption[] = [
		{
			id: Importance.MUST,
			name: t("projectRequirements.importance.must"),
		},
		{
			id: Importance.SHOULD,
			name: t("projectRequirements.importance.should"),
		},
	];

	const handleSelectChange = (
		value: Importance.MUST | Importance.SHOULD,
		index: number,
	) => {
		const newState = [...projectRequirements];
		newState[index]["importance"] = value;
		setProjectRequirements(newState);
	};

	const handleInputChange = (
		event: ChangeEvent<HTMLTextAreaElement>,
		key: "requirement" | "experience",
		index: number,
	) => {
		const newState = [...projectRequirements];
		newState[index][key] = event.target.value;
		setProjectRequirements(newState);
	};

	const handleAddProjectRequirement = (index: number) => {
		const newState = [...projectRequirements];
		newState.splice(index + 1, 0, { ...EMPTY_PROJECT_REQUIREMENTS });
		setProjectRequirements(newState);
	};

	const handleDeleteProjectRequirement = (index: number) => {
		if (projectRequirements.length === 1) {
			return setProjectRequirements([{ ...EMPTY_PROJECT_REQUIREMENTS }]);
		} else {
			const newState = projectRequirements.filter(
				(_: any, i: number) => i !== index,
			);
			return setProjectRequirements(newState);
		}
	};

	return (
		<div>
			<div className="my-3 ml-3">
				<Heading level={"2"}>{t("projectRequirements.title")}</Heading>
			</div>
			<div className="bg-atMidGrey bg-formTileBackground p-10 sm:rounded-lg">
				{projectRequirements.map((projectRequirement, i) => {
					return (
						<React.Fragment key={i}>
							<div className="mb-3 sm:flex sm:flex-row sm:space-x-3 sm:space-y-0">
								<CustomSelect
									onChange={(e: Importance.MUST | Importance.SHOULD) =>
										handleSelectChange(e, i)
									}
									value={
										projectRequirement.importance || ImportanceOptions[0].name
									}
									options={ImportanceOptions}
									placeholder={"Importance"}
								/>
							</div>
							<div
								className="space-y-3 sm:flex sm:flex-row sm:space-x-3 sm:space-y-0"
								key={i}
							>
								<CustomTextarea
									placeholder={t(
										"projectRequirements.requirements.placeholder",
									)}
									onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
										handleInputChange(e, "requirement", i)
									}
									value={projectRequirement.requirement}
									cols={35}
									rows={5}
								/>
								<CustomTextarea
									placeholder={t("projectRequirements.experience.placeholder")}
									onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
										handleInputChange(e, "experience", i)
									}
									value={projectRequirement.experience}
									cols={35}
									rows={5}
								/>
								<div className="flex flex-row items-center">
									<AddButton
										className={"mr-4"}
										onClick={() => handleAddProjectRequirement(i)}
									/>
									<DeleteButton
										onClick={() => handleDeleteProjectRequirement(i)}
									/>
								</div>
							</div>
							{projectRequirements.length !== i + 1 && (
								<div className="relative flex items-center py-5">
									<div className="flex-grow border-t border-border"></div>
								</div>
							)}
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
};

export default ProjectRequirements;
