import client from "../client";

const axiosClient = client.getAxiosClient();

const getPicture = async (userId: string) => {
	const response = await axiosClient.get(`/picture/${userId}`, {
		responseType: "blob",
		headers: { Accept: "image/webp,*/*" },
	});
	return response;
};

const postPicture = async (userId: string, image: FormData) => {
	const response = await axiosClient.post(`/picture/${userId}`, image, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response;
};

const putPicture = async (userId: string) => {
	const response = await axiosClient.get(`/picture/${userId}`);
	return response;
};
const deletePicture = async (userId: string) => {
	const response = await axiosClient.delete(`/picture/${userId}`);
	return response;
};

export const PictureService = {
	getPicture,
	postPicture,
	putPicture,
	deletePicture,
};
