import CustomTooltip from "../../../components/CustomTooltip";
import { cn } from "../../../lib/utils";

type ActinoButtonProps = {
	Icon: React.FC<React.SVGProps<SVGSVGElement>>;
	tooltip: string;
	onClick?: () => void;
	active?: boolean;
	disabled?: boolean;
};

const ActionButton = (props: ActinoButtonProps) => {
	const { Icon, onClick = () => {}, disabled, tooltip, active = false } = props;

	const inActiveClassName =
		"group-hover:from-secondary500 group-hover:to-secondary900 group-hover:animate-tilt group-hover:absolute group-hover:-inset-0.5 group-hover:rounded-xl group-hover:bg-gradient-to-r group-hover:opacity-100 group-hover:blur group-hover:transition group-hover:duration-1000";

	const activeClassName =
		"from-secondary500 to-secondary900 animate-tilt absolute -inset-0.5 rounded-xl bg-gradient-to-r opacity-100 blur transition duration-1000";

	const activeBg = active
		? "bg-orange text-white"
		: "bg-formTileBackground text-orange";
	const disabledStyling = disabled && "opacity-50 cursor-not-allowed";
	return (
		<CustomTooltip content={tooltip}>
			<div className="grid items-start justify-center">
				<div className="group relative">
					{!disabled && (
						<div className={active ? activeClassName : inActiveClassName}></div>
					)}
					<button
						disabled={disabled}
						className={cn(
							"relative flex h-8 w-8 items-center justify-center divide-x rounded-full border border-orange text-orange",
							activeBg,
							disabledStyling,
						)}
						onClick={onClick}
					>
						<Icon className="h-5 w-5" />
					</button>
				</div>
			</div>
		</CustomTooltip>
	);
};

export default ActionButton;
