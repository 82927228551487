import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { PictureService } from "../../api/services/PictureService";
import ProfileService from "../../api/services/ProfileService";
import { REACT_QUERY_KEYS } from "../../common/constants";
import { ProfileSections, URL_PARAMS } from "../../common/enums";
import { getParams } from "../../common/helper";
import CustomNavbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Skeleton } from "../../components/ui/skeleton";
import { useToast } from "../../components/ui/use-toast";
import { type IEmployeeProfile } from "../../interfaces/EmployeeProfile";
import Education from "./components/Education";
import GeneralKnowledge from "./components/GeneralKnowledge";
import Introduction from "./components/Introduction";
import JobExperience from "./components/JobExperience";
import Overview from "./components/Overview";
import ProjectExperience from "./components/ProjectExperience";
import ProjectRequirements from "./components/ProjectRequirements";

const EmployeeProfile = () => {
	const [employeeProfile, setEmployeeProfile] =
		useState<IEmployeeProfile | null>(null);
	const [imageFile, setImageFile] = useState<File | null>(null);

	const { toast } = useToast();
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const [queryParameters] = useSearchParams();
	const paramUserId = queryParameters.get(URL_PARAMS.USER_ID);
	const paramLanguage = queryParameters.get(URL_PARAMS.LANGUAGE);
	const paramVariant = queryParameters.get(URL_PARAMS.VARIANT);

	// Memoize query parameters and derived values
	const queryParamsMemo = useMemo(
		() => getParams(queryParameters),
		[queryParameters],
	);

	// Query for fetching profile data
	const getProfileQuery = useQuery({
		queryKey: [
			REACT_QUERY_KEYS.GET_PROFILE,
			paramUserId,
			paramLanguage,
			paramVariant,
		],
		queryFn: () => ProfileService.getProfile(queryParamsMemo),
		onSuccess: (data) => {
			const updatedData = {
				...data,
				profile: {
					...data.profile,
					projectExperiences: data.profile.projectExperiences.map((pe) => ({
						...pe,
						id: pe.id || uuidv4(), // Generate id only if it doesn't already exist
					})),
					jobExperiences: data.profile.jobExperiences.map((je) => ({
						...je,
						id: je.id || uuidv4(), // Generate id only if it doesn't already exist
					})),
					education: data.profile.education.map((edu) => ({
						...edu,
						id: edu.id || uuidv4(), // Generate id only if it doesn't already exist
					})),
				},
			};
			setEmployeeProfile(updatedData);
		},
		onError() {
			toast({
				title: t("fetching.getProfileQuery.onError.title"),
				description: t("fetching.getProfileQuery.onError.description"),
			});
			setEmployeeProfile(null);
		},
		retry: 2,
		refetchOnWindowFocus: false,
		enabled: !!paramUserId && !!paramLanguage && !!paramVariant,
	});

	const getPictureQuery = useQuery({
		queryKey: [REACT_QUERY_KEYS.GET_IMAGE, paramUserId],
		queryFn: () => {
			setImageFile(null);
			return PictureService.getPicture(paramUserId as string);
		},
		onSuccess: (response) => {
			const imageBlob = response.data;
			setImageFile(imageBlob);
		},
		onError(err) {
			console.error(err);
		},
		retry: 2,
		refetchOnWindowFocus: false,
		enabled: !!paramUserId && !!paramLanguage && !!paramVariant,
	});

	const postPictureMutation = useMutation({
		mutationFn: (imageFile: File) => {
			const formData = new FormData();
			formData.append("picture", imageFile);
			return PictureService.postPicture(paramUserId as string, formData);
		},
		onSuccess: () => {
			if (employeeProfile) {
				toast({
					title: t("fetching.postPictureMutation.onSuccess.title"),
				});
				queryClient.invalidateQueries(REACT_QUERY_KEYS.GET_IMAGE);
			}
		},
		onError: () => {
			if (employeeProfile) {
				toast({
					title: t("fetching.postPictureMutation.onError.title"),
				});
			}
		},
	});

	const deletePictureMutation = useMutation({
		mutationFn: () => {
			return PictureService.deletePicture(paramUserId as string);
		},
		onSuccess: () => {
			if (employeeProfile) {
				toast({
					title: t("fetching.deletePictureMutation.onSuccess.title"),
				});
				queryClient.invalidateQueries(REACT_QUERY_KEYS.GET_IMAGE);
			}
		},
		onError: () => {
			if (employeeProfile) {
				toast({
					title: t("fetching.deletePictureMutation.onError.title"),
				});
			}
		},
	});

	const setGenericState = useCallback(
		(stateMember: string, state: any) => {
			if (!employeeProfile) {
				return;
			}
			setEmployeeProfile({
				...employeeProfile,
				profile: { ...employeeProfile.profile, [stateMember]: state },
			});
		},
		[employeeProfile],
	);

	return (
		<Fragment>
			<CustomNavbar />
			<div className="ml-10 grid grid-cols-12">
				<div className="col-span-3 ml-10 mt-24">
					<Sidebar employeeProfile={employeeProfile as IEmployeeProfile} />
				</div>
				<div className="col-span-8 mb-52 mt-14">
					{getProfileQuery.isLoading && <Skeleton className="h-10 w-[250px]" />}
					{employeeProfile && !getProfileQuery.isLoading && (
						<>
							<Introduction
								introduction={employeeProfile.profile.intro}
								setIntroduction={(state) =>
									setGenericState(ProfileSections.INTRO, state)
								}
								postImage={(imageFile: any) =>
									postPictureMutation.mutate(imageFile)
								}
								deleteImage={() => deletePictureMutation.mutate()}
								imageFile={imageFile}
								setImageFile={setImageFile}
							/>
							<Overview
								overview={employeeProfile.profile.overview}
								setOverview={(state) =>
									setGenericState(ProfileSections.OVERVIEW, state)
								}
							/>
							<Education
								education={employeeProfile.profile.education}
								setEducation={(state) =>
									setGenericState(ProfileSections.EDUCATION, state)
								}
							/>
							{employeeProfile.variantId !== "main" && (
								<ProjectRequirements
									projectRequirements={
										employeeProfile.profile.projectRequirements
									}
									setProjectRequirements={(state) =>
										setGenericState(ProfileSections.PROJECT_REQUIREMENTS, state)
									}
								/>
							)}
							<JobExperience
								jobExperiences={employeeProfile.profile.jobExperiences}
								setJobExperiences={(state) =>
									setGenericState(ProfileSections.JOB_EXPERIENCES, state)
								}
							/>
							<GeneralKnowledge />
							<ProjectExperience
								projectExperiences={employeeProfile.profile.projectExperiences}
								setProjectExperiences={(state) =>
									setGenericState(ProfileSections.PROJECT_EXPERIENCES, state)
								}
							/>
						</>
					)}
				</div>
				<div className="col-span-1"></div>
			</div>
		</Fragment>
	);
};

export default EmployeeProfile;
