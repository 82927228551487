export enum Importance {
	MUST = "must",
	SHOULD = "should",
}

export interface IProjectRequirements {
	importance: Importance.MUST | Importance.SHOULD;
	requirement: string;
	experience: string;
}
