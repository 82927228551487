import { InfoCircledIcon } from "@radix-ui/react-icons";

import { cn } from "../lib/utils";
import CustomTooltip from "./CustomTooltip";
import { Label } from "./ui/label";

const CustomLabel = (props: {
	label: string;
	tooltip?: string;
	className?: string;
}) => {
	return (
		<div className={cn("mb-2 flex flex-row", props.className)}>
			<Label className="text-base font-bold text-text">{props.label}</Label>
			{props.tooltip && (
				<CustomTooltip content={props.tooltip}>
					<InfoCircledIcon className="ml-1" />
				</CustomTooltip>
			)}
		</div>
	);
};

export default CustomLabel;
