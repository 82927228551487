import type IProfileList from "../../interfaces/common/IProfileList";
import HttpService from "../client";
import { type GetListProfilesSchemaType } from "../schemas/backendTypes";
import {
	getListProfilesOfUserSchema,
	getListProfilesSchema,
} from "../schemas/ListSchema";
import { transformProfilesOfUserBeToFe } from "../transform/transformList";

const axiosClient = HttpService.getAxiosClient();

const getProfiles = async (
	limit: number = 1000,
	offset: number = 0,
): Promise<GetListProfilesSchemaType> => {
	const response = await axiosClient.get(
		`/list?limit=${limit}&offset=${offset}`,
	);
	return getListProfilesSchema.parse(response.data);
};

const getProfilesOfUser = async (userId: string): Promise<IProfileList[]> => {
	const response = await axiosClient.get(`/list/${userId}`);
	const validatedProfilesOfUser = getListProfilesOfUserSchema.parse(
		response.data,
	);
	return transformProfilesOfUserBeToFe(validatedProfilesOfUser);
};

const ListProfilesService = {
	getProfiles,
	getProfilesOfUser,
};

export default ListProfilesService;
