export const Heading = (props: {
	children: React.ReactNode;
	level: string;
}) => {
	const { children, level } = props;
	switch (level) {
		case "1":
			return <h1 className="text-2xl font-semibold">{children}</h1>;
		case "2":
			return <h2 className="text-xl font-semibold">{children}</h2>;
		case "3":
			return <h3 className="text-lg font-semibold">{children}</h3>;
		case "4":
			return <h4 className="text-base font-semibold">{children}</h4>;
		case "5":
			return <h5 className="text-sm font-semibold">{children}</h5>;
		case "6":
			return <h6 className="text-xs font-semibold">{children}</h6>;
		default:
			return <h1 className="text-2xl font-semibold">{children}</h1>;
	}
};
