import { cn } from "../lib/utils";

const CustomInput = (props: any) => {
	const width = props.width || "max-w-sm";
	return (
		<div className={`grid w-full items-center gap-1.5 ${width}`}>
			<input
				{...props}
				className={cn(
					"rounded border-border bg-inputBackground leading-none text-text focus:border-orange focus:outline-none focus:ring-0",
					props.classname,
				)}
			/>
		</div>
	);
};

export default CustomInput;
